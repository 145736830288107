import React from 'react';
// import TaxCalculator from '../components/taxCalculator';
import Layout from '../components/Layout';
import {
  Container,
  TwoColumnContainer,
  LeftColumnContainer,
  RightColumnContainer,
} from '../components/misc/Layouts';
import { SectionHeading } from '../components/misc/Typography';
const Calculator = () => {
  return (
    <Layout>
      <Container>
        <TwoColumnContainer>
          <SectionHeading>calculator coming soon</SectionHeading>

          <LeftColumnContainer>{}</LeftColumnContainer>
          <RightColumnContainer>{}</RightColumnContainer>
        </TwoColumnContainer>
      </Container>
    </Layout>
  );
};

export default Calculator;
